import React from "react";

import "./partners-block.scss";

import RazerGold from "../../images/partners/razer-gold.svg";
import NightMode from "../../images/partners/night-mode.svg";
import RVCA from "../../images/partners/rvca.svg";
import Razer from "../../images/partners/razer.svg";
import Zoom from "../../images/partners/zoom.svg";
import Microsoft from "../../images/partners/microsoft.svg";
import Xset from "../../images/partners/xset.svg";
import FairPlayAlliance from "../../images/partners/fair-play-alliance.svg";

const PartnersBlock = () => {
    return (
        <div className="partners-block">
            <h3 className="partners-block__title">Our Partners</h3>
            <section id="partners">
                <img src={RazerGold} className="razer-gold" alt="Razer Gold" aria-hidden loading="lazy" />
                <img src={Razer} className="razer" alt="Razer" aria-hidden loading="lazy" />
                <img src={Xset} className="xset" alt="XSET" aria-hidden loading="lazy" />
                <img src={NightMode} className="night-mode" alt="Nightmode" aria-hidden loading="lazy" />
                <img src={RVCA} className="rvca" alt="RVCA" aria-hidden loading="lazy" />
                <img src={Zoom} className="zoom" alt="Zoom" aria-hidden loading="lazy" />
                <img src={Microsoft} className="microsoft" alt="MicroSoft" aria-hidden loading="lazy" />
                <img src={FairPlayAlliance} className="fair-play-alliance" alt="Fair Play Alliance" aria-hidden loading="lazy" />
            </section>
        </div>
    )
};

export default PartnersBlock;